'use strict'
import 'whatwg-fetch'

import React from 'react';
import { render } from 'react-dom';
import ListHome from './components/ListHome';
import List from './components/List';
import Rental from './components/Rental';
import Search from './components/Search';
import Weather from './components/Weather';
import Tabs from './components/Tabs';
import AOS from 'aos';
import scrollTo from 'scroll-to';
import BookingSync from './components/bookingsync.js'

import fr from '../templates/locals/fr_FR.json'
import en from '../templates/locals/en_EN.json'
import { setTimeout } from 'timers';

const lang = document.querySelector('html').getAttribute('lang')

window.wLocals = lang == 'en' ? en : fr
window.wLocals.key = lang

const getQueryStringValue = (key) => {
  return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

AOS.init({
  offset: 100,
  duration: 800,
  easing: 'ease-in-sine',
  delay: 100,
})



const $backTopButton = document.querySelector('.back-top')
if ($backTopButton) {
  $backTopButton.addEventListener('click', (e) => {
    e.preventDefault()
    scrollTo(0, 0,{
      ease: 'out-sine',
      duration: 500
    })
  })
}

const $maxPopin = document.querySelector('.popin--max')
if ($maxPopin) {
  // setTimeout(() => {
  //   $maxPopin.classList.add('is-open')
  //   $maxPopin.classList.remove('is-closed')
  // }, 2000)

  const $closeButton = document.querySelector('.popin__closeButton')
  $closeButton.addEventListener('click', (e) => {
    $maxPopin.classList.remove('is-open')
    $maxPopin.classList.add('is-closed')
  })
}


const $contact = document.querySelector('#contact-form')
if ($contact) {
  $contact.addEventListener('submit', (e) => {
    e.preventDefault()

    const data = new FormData($contact)

    const details = {
      username: data.get('username'),
      name: data.get('name'),
      email: data.get('email'),
      message: data.get('message'),
      butterflyNet: data.get('butterflyNet')
    }

    const formBody = Object.keys(details).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`).join('&')


    fetch("http://dev.plou.io/api/mail", {
      method: "POST",
      mode: 'cors',
      headers: {'Content-Type':'application/x-www-form-urlencoded'},
      body: formBody
    })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then(response => response.json())
    .then(() => {
      $contact.classList.add('is-sent')
    })
    .catch(console.error)
  })
}

const $homeSlide = document.querySelector('.background-slides')

if ($homeSlide) {
  const $slides = document.querySelectorAll('.background-slides__item')

  let $first = document.querySelector('.background-slides__item.is-first')

  $first.classList.add('is-active')
  $first.classList.remove('is-inactive')
  $first.classList.remove('is-first')

  let i = 0
  setInterval( () => {
    i = i < 4 ? i + 1 : 0
    $slides.forEach(el => {
      el.classList.remove('is-active')
      el.classList.add('is-inactive')
      setTimeout( () => {
        el.classList.remove('is-inactive')
      }, 2200)
    })
    $slides[i].classList.add('is-active')
    $slides[i].classList.remove('is-inactive')
  }, 6000)
}


// Website wide scripts
// @Author Dummy Team

const navToggle = document.querySelector('.nav-main-toggle')
const navMain = document.querySelector('.nav-main')

navToggle.addEventListener('click', (e) => {
  e.preventDefault()
  navMain.classList.toggle('is-active')
  navToggle.classList.toggle('is-open')
})

window.onpageshow = (e) => {
  if (e.persisted) {
    navMain.classList.remove('is-active')
    navToggle.classList.remove('is-open')
  }
}

const $weather = document.querySelector("#weather")
if ($weather) {
  const weather = new Weather($weather)
}

const $tabs = document.querySelectorAll(".tab-nav__link")
if ($tabs.length) {
  const tabs = new Tabs($tabs)
}

// Single Rental

const $rental = document.querySelector('#rental')
if ($rental) {
  const defaults = {
    startDate: getQueryStringValue("start_at"),
    endDate: getQueryStringValue("end_at"),
    adults: getQueryStringValue("adults"),
    children: getQueryStringValue("children")
  }
  render( <Rental defaults={defaults} rental={$rental.dataset.id}/>, $rental);
}

// Home Rental list
const $homeRentals = document.querySelector('#react-rentals-home')
if ($homeRentals) {
  // Sémillon, Sauvignon, Merlot, Petit verdot
  const filter = [33280, 32557, 31690, 32552]

  render( <ListHome filter={filter}/>, $homeRentals);
}

const $rentals = document.querySelector('#react-rentals')
if ($rentals) {
    const query = {
    startDate: getQueryStringValue("start_at"),
    endDate: getQueryStringValue("end_at"),
    adults: getQueryStringValue("adults"),
    children: getQueryStringValue("children")
  }
  const hasQuery = query.start_at || query.endDate || query.adults || query.children
  const queryString = hasQuery ? `?start_at=${query.startDate}&end_at=${query.endDate}&adults=${query.adults}&children=${query.children}` : ''

  render(<List queryString={queryString}/>, $rentals);
}

const $rentalsSearch = document.querySelector('#react-rentals-search')
if ($rentalsSearch) {
  const bookingSync = new BookingSync()
  const query = {
    startDate: getQueryStringValue("start_at"),
    endDate: getQueryStringValue("end_at"),
    adults: getQueryStringValue("adults"),
    children: getQueryStringValue("children")
  }
  const hasQuery = query.start_at || query.endDate || query.adults || query.children
  const queryString = hasQuery ? `?start_at=${query.startDate}&end_at=${query.endDate}&adults=${query.adults}&children=${query.children}` : ''

  const max = ( query.adults > 8 || query.children > 8 )

  bookingSync.fetchRentalsIdByDate()
  .then(filter => {
    render(<List filter={filter} queryString={queryString} max={max}/>, $rentalsSearch);
  })
}

const $searchBar = document.querySelector('#search-bar')
if ($searchBar) {
  const defaults = {
    startDate: getQueryStringValue("start_at"),
    endDate: getQueryStringValue("end_at"),
    adults: getQueryStringValue("adults"),
    children: getQueryStringValue("children")
  }
  render(<Search defaults={defaults} />, $searchBar);
}
